import { bootstrap } from "@ms-fabric/workload-client";
import {
  getStorageProfiseeUrl,
  removeStorageProfiseeUrl,
  setStorageError,
  setStorageIsAuthLoading,
} from "app/MasterDataProduct/Auth/authUtils";
import {
  createAuthURL,
  profiseeAuthRedirectPath,
  profiseeSilentAuthRedirectPath,
  staticAuthSettings,
} from "app/MasterDataProduct/Auth/useAuthSettings";
import { UserManager } from "oidc-client-ts";

function printFormattedAADErrorMessage(hashMessage: string): void {
  const hashString = hashMessage.slice(1);

  // Decode URL encoding and parse key-value pairs
  const searchParams = new URLSearchParams(hashString);
  const formattedMessage: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    formattedMessage[key] = decodeURIComponent(value);
  });

  // Print formatted message
  document.documentElement.innerHTML =
    "There was a problem with the consent, open browser debug console for more details";
  for (const key in formattedMessage) {
    if (Object.prototype.hasOwnProperty.call(formattedMessage, key)) {
      console.log(`${key}: ${formattedMessage[key]}`);
    }
  }
}

const profiseeUrl = getStorageProfiseeUrl();

const url = new URL(window.location.href);
if (url.pathname?.startsWith(profiseeAuthRedirectPath)) {
  setStorageIsAuthLoading(true);
  new UserManager({
    ...staticAuthSettings,
    authority: createAuthURL(profiseeUrl),
  })
    .signinRedirectCallback()
    .then(() => {
      console.log("Log in");
      removeStorageProfiseeUrl();
      setStorageIsAuthLoading(false);
    })
    .catch((error) => {
      setStorageError(error);
      setStorageIsAuthLoading(false);
    });
}

if (url.pathname?.startsWith("/login")) {
  setStorageError(null);
  new UserManager({
    ...staticAuthSettings,
    authority: createAuthURL(profiseeUrl),
  })
    .signinRedirect()
    .then(() => {
      console.log("Log in");
    })
    .catch((error) => {
      setStorageError(error);
    })
    .finally(() => {
      window.close();
    });
}

if (url.pathname?.startsWith(profiseeSilentAuthRedirectPath)) {
  new UserManager(staticAuthSettings)
    .signinSilentCallback()
    .then(function () {
      console.log("signin silent callback response success");
    })
    .catch(function (err) {
      console.error(err);
    });
}

/** This is used for authentication API as a redirect URI.
 * Delete this code if you do not plan on using authentication API.
 * You can change the redirectUriPath to whatever suits you.
 */
const redirectUriPath = "/close";
if (url.pathname?.startsWith(redirectUriPath)) {
  // Handle errors, Please refer to https://learn.microsoft.com/en-us/entra/identity-platform/reference-error-codes
  if (url?.hash?.includes("error")) {
    // Handle missing service principal error
    if (url.hash.includes("AADSTS650052")) {
      printFormattedAADErrorMessage(url?.hash);
      // handle user declined the consent error
    } else if (url.hash.includes("AADSTS65004")) {
      printFormattedAADErrorMessage(url?.hash);
    } else {
      window.close();
    }
  } else {
    // close the window in case there are no errors
    window.close();
  }
}

console.log("****Runtime: Environment Variables****");
console.log("process.env.WORKLOAD_NAME: " + process.env.WORKLOAD_NAME);
console.log("process.env.WORKLOAD_BE_URL: " + process.env.WORKLOAD_BE_URL);
console.log("**************************************");

bootstrap({
  initializeWorker: () =>
    import("./index.worker").then(({ initialize }) => initialize()),
  initializeUI: () =>
    import("./index.ui").then(({ initialize }) => initialize()),
}).catch(() => {
  // don't show runtime errors when when signing in
});
