import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type ConnectionContextType = {
  profiseeUrl: string;
  saveProfiseeUrl?: (profiseeUrl: string) => void;
};

const ConnectionContext = createContext<ConnectionContextType>({
  profiseeUrl: "",
});
ConnectionContext.displayName = "ConnectionContext";

// Check if this could be merged with AuthProvider
export const ConnectionProvider: React.FC = ({ children }) => {
  const [profiseeUrl, setProfiseeUrl] = useState<string>("");

  const saveProfiseeUrl = useCallback((profiseeUrl) => {
    setProfiseeUrl(profiseeUrl);
  }, []);

  const value = useMemo(() => {
    return {
      profiseeUrl,
      saveProfiseeUrl,
    };
  }, [profiseeUrl, saveProfiseeUrl]);

  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnectionContext = () => {
  const context = useContext(ConnectionContext);

  return context;
};
