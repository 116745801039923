const authErrorKey = "authError";
const profiseeUrlKey = "profiseeUrl";
const isAuthLoadingKey = "isAuthLoading";

export const getStorageProfiseeUrl = () => {
  return localStorage.getItem(profiseeUrlKey);
};

export const setStorageProfiseeUrl = (url: string) => {
  return localStorage.setItem(profiseeUrlKey, url);
};

export const removeStorageProfiseeUrl = () => {
	return localStorage.removeItem(profiseeUrlKey);
  };

export const setStorageError = (error: Error) => {
  let stringifiedError: string;
  try {
    stringifiedError = JSON.stringify(error);
  } catch (error) {
    stringifiedError = "";
  }

  localStorage.setItem(authErrorKey, stringifiedError);
};

export const getStorageIsAuthLoading = () => {
  return localStorage.getItem(isAuthLoadingKey);
};

export const setStorageIsAuthLoading = (isLoading: boolean) => {
  return localStorage.setItem(isAuthLoadingKey, JSON.stringify(isLoading));
};
